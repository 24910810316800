<template>
  <div>
    <v-card
      text
      class="black--text rounded"
      :to="{ name: 'event', params: { slug: post.slug } }"
    >
      <v-img
        class="round-img"
        :src="post.images[0].thumbnail"
        :alt="post.images[0].thumbnail"
        height="130px"
        width="100%"
      >
      </v-img>
      <v-card-text>
        <b class="black--text">{{ post.title }}</b
        ><br />
        {{ post.location }}
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ["post"],
};
</script>
<style scoped>
.rounded {
  border-radius: 14px !important;
}

.black--text {
  font-size: 16px;
  font-weight: 700;
}

.round-img {
  object-fit: cover;
  background-color: transparent;
  object-position: center;
}
.fill {
  min-height: 50px;
}
.my-2 {
  margin: 8px 0;
}
.rating {
  background-color: #37464d;
  color: white;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  width: auto;
  max-width: 40px;
}

</style>
