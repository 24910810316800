<template>
  <v-app class="main-bg">
    <v-navigation-drawer
      persistent
      :mini-variant="miniVariant"
      :clipped="clipped"
      v-model="drawer"
      enable-resize-watcher
      fixed
      app
      style="z-index: 9;"
    >
      <v-flex pa-0 class="nav-hero">
        <img
          :src="require('@/assets/images/nav-hero.jpg')"
          height="auto"
          width="100%"
          :alt="title"
        /><br />
        <div v-if="!isUser">
          <button class="orange-btn" @click="login">LOGIN/REGISTER</button>
        </div>
        <div v-else>
          <button class="orange-btn" @click="logout">LOGOUT</button>
        </div>
      </v-flex>
      <v-list>
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :to="item.route"
          v-on:click="handleRoute(item)"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar dense clipped-left dark color="primary">
      <img
        src="@/assets/images/nav-icon.svg"
        alt="$t('nav')"
        @click.stop="drawer = !drawer"
        class="hamburger"
      />
      <v-spacer></v-spacer>
      <v-app-bar-title>
        <router-link to="/">
          <img
            src="@/assets/images/logo_white.png"
            class="toolbar-img justify-center"
            :alt="title"
          />
        </router-link>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn icon v-scroll-to="'#searchbar'" to="results">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn icon @click.stop="translatedialog = true">
        <v-icon>mdi-translate</v-icon>
      </v-btn>
      <v-dialog
        v-model="translatedialog"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-title>{{ $t("change-language") }}</v-card-title>
          <v-card-text>
            <v-radio-group v-model="lang">
              <v-radio :label="$t('english')" value="en">{{
                $t("english")
              }}</v-radio>
              <v-radio :label="$t('indonesia')" value="id">{{
                $t("indonesia")
              }}</v-radio>
            </v-radio-group>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="reloadPage">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app-bar>
    <v-main>
      <div id="searchbar"></div>
      <router-view />
    </v-main>
    <div style="display: block; width: 100%;height: 10px; background-color: #073951; margin-bottom: 8px;">

    </div>
    <v-footer height="auto" color="darken-4 white--text text-center"
      style="background-color: #073951 !important;padding-top: 20px !important  ;"
    >
      <v-btn
        dark
        fab
        fixed
        bottom
        right
        color="primary"
        href="https://wa.me/6285857082012"
        v-show="$route.name !== 'activity' ? true : false"
      >
        <v-icon>mdi-whatsapp</v-icon>
      </v-btn>

      <v-layout justify-center row wrap>
        <v-flex xs12 pa-4>
          <img
            src="@/assets/images/logo_white.png"
            height="40px"
            width="auto"
            alt="$t('balitripcenter')"
          /><br />
          <span
            >{{
              $t(
                "welcome_to_Indonesia_with_thousand_of_beautiful_island_and_culture"
              )
            }} </span
          ><br />
        </v-flex>
        <v-layout row align-center>
          <v-flex xs6>
            <a
              href="https://www.instagram.com/dewaputu_btc"
              style="display: flex;justify-content: center;gap:10px;color:white !important;text-decoration: none;"
              target="_blank"
            >
              <img
          src="@/assets/images/icons/instagram.svg"
          height="30px"
          alt="$t('follow-us')"
              />
              <div style="display: flex; justify-content: flex-start; align-items: start;flex-direction: column;">
          <span class="small">{{ $t("follow_us_on") }}</span>
          <span class="small">@dewaputu_btc</span>
              </div>
            </a>
          </v-flex>
          <v-flex xs6>
            <img
              src="@/assets/images/icons/wonderful_Indonesia.svg"
              height="60px"
              alt="$t('follow-us')"
            />
          </v-flex>
        </v-layout>
        <v-flex xs12 py-2>
          <v-divider dark></v-divider>
        </v-flex>
        <v-flex py-3 text-center white--text xs12
          style="font-size: 12px !important;font-weight: 400 !important;"
        >
            &#169; Bali Trip Center 2018-{{ new Date().getFullYear() }} - All Rights Reserved
        </v-flex>
      </v-layout>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      langs: ["id", "en"],
      translatedialog: false,
      search: false,
      query: null,
      links: [
        { route: "howtobook", title: this.$t("how-to-book") },
        { route: "term", title: this.$t("term-payment") },
        { route: "cancelation", title: this.$t("cancelation-policy") },
      ],
      clipped: false,
      drawer: null,
      fixed: false,
      guest_items: [
        {
          icon: "mdi-bank",
          title: this.$t("home"),
          route: "/",
        },
        {
          icon: "mdi-developer-board",
          title: this.$t("activities"),
          route: "/activity",
        },
        {
          icon: "mdi-map",
          title: this.$t("destination"),
          route: "/destination",
        },
        {
          icon: "mdi-car",
          title: this.$t("transport"),
          route: "/transport",
        },
        {
          icon: "mdi-image",
          title: this.$t("gallery"),
          route: "/gallery",
        },
        {
          icon: "mdi-book-marker",
          title: this.$t("tips"),
          route: "/tips",
        },
        {
          icon: "mdi-calendar",
          title: this.$t("event"),
          route: "/event",
        },
        {
          icon: "mdi-cart",
          title: this.$t("special-offer-l"),
          route: "/specialoffer",
        },
        {
          icon: "mdi-help-circle-outline",
          title: this.$t("help-and-faq"),
          route: "/helpandfaq",
        },
        {
          icon: "mdi-information",
          title: this.$t("about"),
          route: "/aboutus",
        },
        {
          icon: "mdi-account",
          title: this.$t("login"),
          route: "/login",
        },
        {
          icon: "mdi-account",
          title: this.$t("register"),
          route: "/register",
        },
      ],
      user_items: [
        {
          icon: "mdi-bank",
          title: this.$t("home"),
          route: "/",
        },
        {
          icon: "mdi-developer-board",
          title: this.$t("activities"),
          route: "/activity",
        },
        {
          icon: "mdi-map",
          title: this.$t("destination"),
          route: "/destination",
        },
        {
          icon: "mdi-car",
          title: this.$t("transport"),
          route: "/transport",
        },
        {
          icon: "mdi-image",
          title: this.$t("gallery"),
          route: "/gallery",
        },
        {
          icon: "mdi-book-marker",
          title: this.$t("tips"),
          route: "/tips",
        },
        {
          icon: "mdi-calendar",
          title: this.$t("event"),
          route: "/event",
        },
        {
          icon: "mdi-cart",
          title: this.$t("special-offer-l"),
          route: "/specialoffer",
        },
        {
          icon: "mdi-help-circle-outline",
          title: this.$t("help-and-faq"),
          route: "/helpandfaq",
        },
        {
          icon: "mdi-information",
          title: this.$t("about"),
          route: "/aboutus",
        },
        {
          icon: "mdi-account",
          title: this.$t("profile"),
          route: "/profile",
        },
        {
          icon: "mdi-launch",
          title: this.$t("logout"),
        },
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: "Balitripcenter.com",
    };
  },
  methods: {
    reloadPage() {
      this.translatedialog = false;
      this.$router.go();
    },
    handleRoute(item) {
      if (item.title === this.$t("logout")) {
        this.logout();
      } else {
        this.$router.push(item.route);
      }
    },
    logout() {
      this.$store.dispatch("removeToken");
      this.$store.dispatch("removeProfile");
      this.$router.push("/");
    },
    login() {
      this.$router.push("/login");
    },
  },
  computed: {
    lang: {
      get: function () {
        return this.$store.getters.getLanguage;
      },
      set: function (newVal) {
        this.$store.dispatch("setLanguage", {
          language: newVal,
        });
        localStorage.setItem("preferredLanguage", newVal);
      },
    },
    items() {
      return this.isUser ? this.user_items : this.guest_items;
    },
    isUser() {
      return this.$store.getters.isAuthenticated;
    },
  },
  mounted() {
  },
  name: "App",
};
</script>

<style>
.main-bg {
  background-color: #fbf9fa !important;
  overflow-x: hidden;
}

.header-text {
  font-size: 16px;
  font-weight: 700;
  color: #000;
}
.orange--text {
  color: #FF6100 !important;
}

.v-card__text {
  color: #000 !important;
}
</style>
<style scoped>
.hamburger {
  height: 48px;
  width: 48px;
  padding: 14px;
}
.small {
  font-size: 10px;
}
.nav-hero {
  position: relative;
}

.orange-btn {
  background-color: #ffb101;
  border-radius: 18px;
  padding: 8px 20px;
  min-width: 200px;
  position: absolute;
  font-size: 14px;
  font-weight: bold;
  top: 72%;
  left: 10%;
}

.toolbar-img {
  width: auto;
  height: 30px;
  align-content: center;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
.footer-img {
  width: auto;
  height: 35%;
  align-content: center;
  display: flex;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
}
.flink {
  margin: 0 auto;
  padding: 0 4px;
  font-size: 10px;
}
</style>
