import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default new Vuex.Store({
  // initial state
  state: {
    token: '',
    profile: '',
    language: 'id'
  },

  // getters
  getters: {
    isAuthenticated: state => !!state.token,
    getToken: state => state.token,
    getProfile: state => state.profile,
    getLanguage: state => state.language
  },

  // actions
  actions: {
    setToken(context, payload) {
      context.commit('SET_TOKEN', payload.token)
    },
    removeToken(context) {
      context.commit('REMOVE_TOKEN')
    },
    setProfile(context, payload) {
      context.commit('SET_PROFILE', payload.profile)
    },
    removeProfile(context) {
      context.commit('REMOVE_PROFILE')
    },
    setLanguage(context, payload) {
      context.commit('SET_LANGUAGE', payload.language)
    }
  },

  // mutations
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
    },
    REMOVE_TOKEN(state) {
      state.token = ''
    },
    SET_PROFILE(state, profile) {
      state.profile = profile
    },
    REMOVE_PROFILE(state) {
      state.profile = ''
    },
    SET_LANGUAGE(state, language) {
      state.language = language
    }

  },
  plugins: [vuexLocal.plugin]
})