<template>
  <div>
    <div class="gallery" v-if="photos && photos.length" v-show="!loading">
      <v-container pa-2 fluid>
        <v-row align="center" class="mb-4">
          <v-col cols="8">
            <div class="headline">{{ $t("photo-gallery") }}</div>
            <div class="lead">{{ $t("our-happy-customer") }}</div>
            <v-btn
              class="mt-2 custom-btn"
              style="background: #fff !important; border-radius: 9px !important;text-transform: lowercase;"
              outlined
              :to="{ name: 'gallery' }"
              small
              roundedoutline
            >
              <img
                src="@/assets/images/icons/gallery-new.svg"
                alt="arrow-right"
                style="width: 20px; height: 20px; margin-right: 5px"
              />
              {{ $t("view-more") }}
            </v-btn>
          </v-col>

          <v-col cols="4" v-if="photos.length > 0">
            <v-img
              class="thumbnail-image"
              :src="photos[0].thumbnail"
              :alt="photos[0].thumbnail"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>

      <!-- VueSlickCarousel -->
      <VueSlickCarousel v-bind="settings" class="gallery-carousel">
        <div v-for="(photo, index) in photos" :key="index">
          <div class="gallery-item-card">
            <img
              :src="photo.thumbnail"
              :alt="photo.thumbnail"
              class="gallery-image"
            />
          </div>
        </div>
      </VueSlickCarousel>
    </div>
    <loading :loading="loading"></loading>
  </div>
</template>

<script>
import axios from "axios";
// Import VueSlickCarousel and styles
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

const api = "https://balitripcenter.com/api/galleryfeature";

export default {
  name: "Gallery",
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      loading: false,
      photos: [], // Gallery photos
      activeIndex: 0, // Track active item index
      settings: {
        centerMode: true,
        centerPadding: "0px",
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: false,
        dots: false,
      },
    };
  },
  created() {
    this.getInitialPosts();
  },
  methods: {
    getInitialPosts() {
      this.loading = true;
      axios
        .get(api)
        .then((response) => {
          this.loading = false;
          this.photos = response.data.items;
        })
        .catch((e) => {
          this.loading = false;
          console.error(e);
        });
    },
  },
};
</script>

<style scoped>
.headline {
  font-size: 25px !important;
  font-weight: 400 !important;
}

.lead {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.custom-btn {
  font-size: 16px !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.gallery {
  padding: 30px 8px;
  border-radius: 8px;
  background: url("~@/assets/images/banner-gallery.png") no-repeat center center;
  background-size: cover;
}

.gallery-carousel {
  margin-top: 24px;
}

.gallery-item-card {
  margin: 20px 10px; /* Space between items */
  transition: all 0.3s ease;
  width: 124px;
  height: 198px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-active .gallery-item-card {
  width: 114px;
  transition: all 0.3s ease;
}

.thumbnail-image {

  border-radius: 25px;
  width: 108px;
  height: 102px;
}

.gallery-item-card img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  transition: all 0.3s ease;
}

.slick-slide.slick-center .gallery-item-card img {
  height: 224px;
  width: 134px;
  transition: all 0.3s ease;
}

.gallery .slick-slider .slick-list {
  /* overflow-y:visible !important; */
  overflow: visible !important;
}

.gallery-image {
  border-radius: 8px;
}

/* Custom slick carousel modifications */
.slick-track {
  display: flex !important;
  gap: 20px !important; /* Add 20px gap between slides */
  align-items: center !important; /* Align slides vertically to center */
  transition: all 0.3s ease;
}

.slick-slide {
  display: flex !important;
  justify-content: center !important;
  transition: all 0.3s ease;
  align-items: center !important;
  height: 100%; /* Ensure full height for vertical alignment */
}
</style>
