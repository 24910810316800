<template>
  <div>
    <v-container grid-list-md text-center class="category-card">
      <v-layout row wrap>
        <v-flex xs3>
          <v-card
            class="main-card"
            :to="{
              name: 'activity',
              params: { slug: 'bali-airport-transportation-service-badung' },
            }"
          >
            <v-card-text class="center pa-0">
              <img
                src="@/assets/images/airport-new.png"
                height="auto"
                width="100%"
                alt="$t('airport-transfer')"
              /><br />
              <div>
                <div v-html="formatTextToNewline($t('airport-transfer'))"></div>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs3>
          <v-card class="main-card" to="activity">
            <v-card-text class="center pa-0">
              <img
                src="@/assets/images/activity-new.png"
                height="auto"
                width="100%"
                alt="activity"
              /><br />
              <div v-html="formatTextToNewline($t('bali_activity'))"></div>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs3>
          <v-card class="main-card" :to="{ name: 'activities' }">
            <v-card-text class="center pa-0">
              <img
                src="@/assets/images/create-new.png"
                height="auto"
                width="100%"
                alt="transport"
              /><br />
              <div v-html="formatTextToNewline($t('bali_tour'))"></div>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs3>
          <v-card class="main-card" to="destination">
            <v-card-text class="center pa-0">
              <img
                src="@/assets/images/destination-new.png"
                height="auto"
                width="100%"
                alt="destination"
              /><br />
              <div v-html="formatTextToNewline($t('bali_destination'))"></div>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
            <a class="browse-all-btn" href="/all-category">
            <img
              src="@/assets/images/icons/category.svg"
              height="24"
              width="24"
              alt="Browse All Category"
              class="mr-2"
            />
            {{ $t("browse_all_category") }}
            </a>
    </v-container>
  </div>
</template>
<script>
export default {
  methods: {
    formatTextToNewline(text) {
      return text.split(" ").join("<br />");
    },
  },
};
</script>

<style scoped>
.category-card {
  border-radius: 20px;
  padding: 15px;
  background-color: white;
  box-shadow: 0px 1px 50px 0px rgba(0, 0, 0, 0.25);
  /* position: relative; */
  /* bottom: 80px; */
}
.main-card {
  height: 140px;
  border-radius: 18px !important;
  box-shadow: none !important;
  position: relative;
}

.main-card img {
  width: auto;
  height: 49px;
}

.main-card .center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.browse-all-btn {
  background: #F5F5F5 !important;
  background-color: #F5F5F5 !important;
  padding: 15px;
  border-radius: 15px;
  border:none !important;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
}
</style>
