<template>
  <v-card elevation="4" class="rounded black--text review-card">
    <div
      class="d-flex"
      style="justify-content: space-between; margin-bottom: 15px; width: 100%"
    >
      <div class="d-flex" style="gap: 10px">
        <v-img
          :src="post.user.image"
          :alt="post.user.image"
          height="50px"
          width="50px"
          class="rounded-circle"
        >
        </v-img>
        <div>
          <h4 style="color:#757575;">{{ post.user.name }}</h4>
          <i class="mdi mdi-star" style="color: orange"></i>
          <b>{{ post.rating }} <small>/5</small></b>
        </div>
      </div>

      <div>
        <small style="color:#757575;">{{ post.updated_at | showDate }}</small>
      </div>
    </div>

    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <!-- Adjust the comment text width dynamically based on images -->
        <v-flex xs8>
          <b>{{ post.commentable.name }}</b>
          <p v-html="post.body" style="color:#757575;"></p>
        </v-flex>

        <!-- Images Section (centered) -->
        <v-flex xs4 v-if="post.images && post.images.length > 0">
          <v-layout row wrap justify-center>
            <!-- Limit to 2 images and display them in a row -->
            <v-flex
              v-for="(image, i) in limitedImages"
              :key="i"
              xs="auto"
              sm="auto"
              class="d-flex justify-center my-2"
            >
              <v-card class="black--text">
                <a :href="image.thumbnail" target="_blank">
                  <v-img
                    :src="image.thumbnail"
                    :alt="image.thumbnail"
                    height="54px"
                    style="border-radius: 7px;"
                    width="54px"
                  >
                  </v-img>
                </a>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: ["post"],
  computed: {
    limitedImages() {
      // Limit images to two
      return this.post.images.slice(0, 2);
    },
  },
};
</script>

<style scoped>
.review-card {
  padding: 10px;
  min-width: calc(100vw - 24px);
}

/* Center images horizontally and ensure they stay in one row */
.v-layout {
  justify-content: center; /* Centers all items in the flex container */
}

.v-flex {
  display: flex;
  justify-content: center; /* Center items inside each flex */
  align-items: center; /* Ensure images are vertically aligned */
}

.v-card {
  margin: 0 10px; /* Space between the image cards */
}
</style>
