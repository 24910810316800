<template>
  <div class="body" @scroll="showHeader">
    <div class="header-contanier2" v-show="isVisible">
      <div class="search-main">
        <div class="search-container">
          <input
            type="text"
            :placeholder="
              $t('find-activities-tours-4g-modem-and-private-transport')
            "
            v-model="queryInput"
          />
          <v-btn
            icon
            color="black"
            @click="toSearch"
            v-on:keyup.enter="toSearch"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="pa-2 scrolling-wrapper" style="margin-bottom: 0 !important">
        <div v-for="item in categories" :key="item.name">
          <div @click="selected(item.id)">
            <div v-if="active != item.id">
              <div class="card">
                <img
                  class="type-img"
                  :src="item.images[0].thumbnail"
                  :alt="item.name"
                />
                <div>{{ capitalizeFirstWord(item.name) }}</div>
              </div>
            </div>
            <div v-else>
              <div class="card active">
                <img
                  class="type-img"
                  :src="item.images[0].thumbnail"
                  :alt="item.name"
                />
                <div>{{ capitalizeFirstWord(item.name) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-contanier">
      <h2 class="title">{{ $t("discover") }}</h2>
      <p>
        {{ $t("discover_sub") }}
      </p>

      <div class="search-main">
        <div class="search-container">
          <input
            type="text"
            :placeholder="
              $t('find-activities-tours-4g-modem-and-private-transport')
            "
            v-model="queryInput"
          />
          <v-btn
            icon
            color="black"
            @click="toSearch"
            v-on:keyup.enter="toSearch"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <div style="background-color: #fff" class="allcategories">
      <div class="category-title">
        {{ $t("all_category") }}
        <router-link to="/categories" class="see-all">
          {{ $t("see-all-different") }}
          <v-icon>mdi-chevron-right</v-icon>
        </router-link>
      </div>
      <div class="pa-2 scrolling-wrapper">
        <div v-for="item in categories" :key="item.name">
          <div @click="selected(item.id)">
            <div v-if="active != item.id">
              <div class="card">
                <img
                  class="type-img"
                  :src="item.images[0].thumbnail"
                  :alt="item.name"
                />
                <div>{{ capitalizeFirstWord(item.name) }}</div>
              </div>
            </div>
            <div v-else>
              <div class="card active">
                <img
                  class="type-img"
                  :src="item.images[0].thumbnail"
                  :alt="item.name"
                />
                <div>{{ capitalizeFirstWord(item.name) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loading :loading="loading"></loading>

    <v-flex sm12 pa-2>
      <div v-if="!isPostShow">
        <activity-recommendation></activity-recommendation>
        <br />
        <activity-popular></activity-popular>
        <br />
        <activity-transport></activity-transport>
        <br />
        <activity-baliact></activity-baliact>
        <br />
        <activity-balitour></activity-balitour>
        <br />
        <activity-water-sport></activity-water-sport>
        <br />
      </div>
      <div v-if="isPostShow">
        <div v-if="posts && posts.length">
          <v-container pa-2 fluid grid-list-md>
            <v-layout row wrap>
              <v-flex pa-3 v-for="(post, i) of posts" :key="i" xs12 sm6 xl4>
                <activity-card-new :post="post"></activity-card-new>
              </v-flex>
            </v-layout>
          </v-container>
          <div class="text-center" v-if="!loading && currentPage != null">
            <v-btn
              rounded
              class="white--text"
              color="green"
              @click="getPosts"
              >{{ $t("load-more") }}</v-btn
            >
          </div>
        </div>
        <v-flex xs12 v-else v-show="!loading" ma-2 text-center>
          <span>{{ $t("this-category-doesnt-have-activity-yet") }}</span>
        </v-flex>
      </div>

      <!-- <home-populars></home-populars> -->
      <!-- <br /> -->
      <!-- <home-recommendations></home-recommendations> -->
      <!-- <br /> -->
      <home-reviews></home-reviews>
      <br />
      <gallery-home></gallery-home>
      <br />
    </v-flex>
    <home-categories :items="items"></home-categories><br />

    <div class="box">
      <v-layout>
        <v-flex xs12 items-center text-center>
          <img
            src="@/assets/images/icons/livechat-new.svg"
            height="auto"
            alt="$t('contact-us')"
            style="margin: auto; width: 84px"
          /><br />
          <h4
            class="text-h4"
            style="
              font-size: 14px !important;
              text-align: center;
              font-weight: 600;
            "
          >
            Live Report
          </h4>
          <span class="caption">{{
            $t("any_question_about_balitripcenter")
          }}</span
          ><br />
          <a class="email" href="https://wa.me/6285857082012" target="_blank">
            <span class="caption">+62 85857082012</span> </a
          ><br />
          <a class="email" href="mailto:support@balitripcenter.com">
            <span class="caption">support@balitripcenter.com</span>
          </a>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import ActivityCardNew from "./ActivityCardNew.vue";
const api = "https://balitripcenter.com/api/type";

export default {
  props: ["type"],
  data() {
    return {
      loading: true,
      posts: [],
      errors: [],
      itemsCategories: [],
      currentPage: api,
      isVisible: false,
      valid: true,
      email: null,
      isPostShow: false,
      categories: [
        {
          id: null,
          name: this.$t("discover"),
          title: this.$t("all-available-activities-at-our-site"),
          des: this.$t("bali-is-an-island-or-actually"),
          created_at: this.$t("2018-07-09-08-28-51"),
          updated_at: this.$t("2018-07-09-08-28-51"),
          images: [
            {
              thumbnail: require("@/assets/images/icons/discover.svg"),
              original: require("@/assets/images/icons/discover.svg"),
            },
          ],
        },
      ],
      category: null,
      category_name: null,
      category_des: null,
      active: null,
      queryInput: null,
    };
  },
  beforeMount() {
    this.getCategories();
    this.fetchData();
  },
  mounted() {
    if (!this.type) {
      this.getPosts();
    } else {
      this.selected(this.type);
      this.active = this.type;
    }
  },
  methods: {
    showHeader(e) {
      this.isVisible = e.target.scrollTop > 450 ? true : false;
    },

    capitalizeFirstWord(string) {
      // set lowercase all and capitalize first
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },

    toSearch() {
      this.$router.push({
        name: "results",
        params: { initialQuery: this.queryInput },
      });
    },
    subscribe() {
      if (this.$refs.form.validate()) {
        http
          .get(`https://balitripcenter.com/sanctum/csrf-cookie`)
          .then((res) => {
            axios
              .post("https://balitripcenter.com/api/subscriber", {
                email: this.email,
              })
              .then((response) => {
                this.email = null;
              })
              .catch((e) => {});
          });
      }
    },
    selected(id) {
      this.posts = [];
      if (id == null) {
        this.currentPage = "https://balitripcenter.com/api/type";
        this.active = null;
        this.isPostShow = false;
      } else {
        this.currentPage = "https://balitripcenter.com/api/type/" + id;
        this.active = id;
        this.isPostShow = true;
      }
      this.getPosts();
    },
    getCategories() {
      axios
        .get("https://balitripcenter.com/api/types")
        .then((response) => {
          this.categories = this.categories.concat(response.data.items);
          //console.log("categories " + this.categories);
        })
        .catch((e) => {});
    },
    fetchData() {
      this.loading = true;
      axios
        .get(`https://balitripcenter.com/api/index`)
        .then((response) => {
          this.loading = false;
          this.itemsCategories = response.data.items;
          //console.log(this.items);
        })
        .catch((e) => {
          this.loading = false;
          this.errors.push(e);
        });
    },
    getPosts() {
      this.loading = true;
      axios
        .get(this.currentPage)
        .then((response) => {
          this.loading = false;
          this.posts = this.posts.concat(response.data.items.data);
          this.category = response.data.category;
          this.currentPage = response.data.items.next_page_url;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
  },
  components: { ActivityCardNew },
};
</script>
<style scoped>
.body {
  overflow: auto;
  height: 100vh; /* Ensures the sticky container has a scrollable parent */
  display: flex;
  flex-direction: column;
}
.header-contanier {
  position: relative;
  width: 100%;
  background-color: #f6f6f6;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 36px 16px;
  border-radius: 0 0 28px 28px;
}

.header-contanier2 {
  position: sticky !important;
  top: 0;
  z-index: 8;
  width: 100%;
  background-color: #f6f6f6;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 8px 10px;
  border-radius: 0 0 28px 28px;
}

.header-contanier h2 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}

.header-image {
  height: 100%;
  width: 100%;
}

.search-container {
  position: relative;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  background-color: #fff;
  border-color: #eee;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.search-container input[type="text"] {
  font-size: 17px;
  padding: 8px;
  width: 100%;
  height: 50px;
  text-indent: 30px;
}
.search-container button {
  position: absolute;
  left: 0;
  padding: 8px;
  font-size: 17px;
  border: none;
  height: 100%;
  color: white;
  background-color: white;
  cursor: pointer;
  border-radius: 15px;
}

.search-container button:hover {
  background: #ccc;
}

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.scrolling-wrapper .card {
  display: flex;
  align-items: center;
  gap: 8px;
  width: max-content;
  min-width: 120px;
  background-color: #f6f6f6;
  margin: 4px 8px;
  height: auto;
  min-height: 60px;
  padding: 8px;
  font-weight: bold;
  flex: 0 0 auto;
  font-size: 14px;
  margin-top: 20px;
  border-radius: 30px;
  border-width: 2px;
  border-style: solid;
  border-color: white;
  scroll-snap-align: center;
}
.scrolling-wrapper .card.active {
  border-color: #18191b;
  background-color: #d9d9d9;
}

.type-img {
  width: 40px;
  height: 40px;
  background: #fff;
  overflow: hidden;
  border-radius: 100%;
  object-fit: contain;
  object-position: center;
  margin-bottom: 4px;
}

.clip img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.rating {
  background-color: #37464d;
  color: white;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  width: auto;
  max-width: 40px;
}

.title {
  color: black;
  font-size: 28px;
  font-weight: 400;
}

.input-group {
  border-radius: 4px;
  margin-top: 12px;
  position: relative;
  height: 40px;
  border-color: black;
  border-style: solid;
  border-width: 2px;
  background: white;
  width: 100%;
}

.button {
  border-radius: 0 4px 4px 0;
  background-color: black;
  padding: 8px 12px;
  position: absolute;
  height: 100%;
  right: 0;
  color: white;
}

.input {
  padding: 8px;
  margin: 0px;
  width: 80%;
  height: 100%;
  left: 0;
  position: absolute;
  background-color: white;
}

.category-title {
  padding: 8px;
  font-size: 18px;
  color: #222;
}

.box {
  background-color: #fff;
  margin: 8px;
  padding: 8px;
  border-radius: 12px;
}

.box img {
  margin-top: 10px;
}

.email,
.email:hover {
  text-decoration: none;
  color: black;
}

.category-title {
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 700;
  color: #222;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.see-all {
  color: #000;
  text-decoration: none !important;
  font-size: 14px;
  font-weight: 600;
}

.see-all:hover {
  text-decoration: none;
}

.see-all v-icon {
  font-size: 16px;
}

.hidden {
  display: none !important;
}
</style>
