<template>
  <div>
    <v-card
      elevation="4"
      class="rounded custom black--text"
      :to="{
        name: 'activity',
        params: { slug: post.slug, type: post.type.slug },
      }"
    >
      <v-img
        :src="post.images[0].thumbnail"
        :alt="post.images[0].thumbnail"
        height="130px"
        width="100%"
      >
      </v-img>
      <v-card-text>
        <v-layout row>
          <v-flex xs12>
            <div>
              <div :class="{ 'one-line': moneyLarge }" class="a-title">
                {{ post.name }}
              </div>
              <br />
              <div :class="{ 'flex-parent': moneyLarge }">
                <div v-if="!moneyLarge">
                  <i class="mdi mdi-star" style="color: orange"></i>
                  {{ post.rating }} / <span style="color:gray;">{{ post.comment_count || 0 }} reviews </span>
                </div>
                <div class="flex-custom">
                  <img
                    src="@/assets/images/icons/maps-marker.svg"
                    alt="arrow-right"
                    style="width: 16px; margin-right: 5px"
                  />
                  <small class="caption" style="color: #000">{{
                    post.location
                  }}</small>
                </div>
                <div v-if="moneyLarge">
                  <i class="mdi mdi-star" style="color: orange"></i>
                  {{ post.rating }} / <span style="color:gray;">{{ post.comment_count || 0 }} reviews </span>
                </div>
              </div>
            </div>
            <v-divider :class="{ 'my-4': moneyLarge, 'my-1': !moneyLarge }"></v-divider>

            </v-divider>
            <div :class="moneyLarge ? 'flex-parent-custom' : 'flex-parent'">  
                <small :class="{'caption': !moneyLarge, 'float-right': moneyLarge}" style="color: gray">{{
                $t("start-from")
              }}</small>
              <span
                v-show="!moneyLarge"
                style="color: #000; font-size: 12px !important"
                class="title float-right"
              >
                {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}
                {{
                  $root.$i18n.locale == "id"
                    ? post.price_idr
                    : post.price_usd | fm
                }}
              </span>
              <span
                  v-show="moneyLarge"
                class=" float-right header-text"
              >
                {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}
                {{
                  $root.$i18n.locale == "id"
                    ? post.price_idr
                    : post.price_usd | fm
                }}
              </span>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    post: Object,
    moneyLarge: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.fill {
  min-height: 50px;
}
.flex-custom {
  display: flex;
  align-items: center;
}

.flex-parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.my-2 {
  margin: 8px 0;
}
.rating {
  background-color: #37464d;
  color: white;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  width: auto;
  max-width: 40px;
}

.rounded {
  border-radius: 14px 14px 14px 14px !important;
}

.caption-large {
  display: inline-block;
  width: max-content
}

.flex-parent-custom {
  display: flex;
  justify-content: end;
  gap: 10px;
}

.a-title {
  color: black;
  font-size: 16px;
  font-weight: 700;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.a-title.one-line {
  -webkit-line-clamp: 1 !important;
}
</style>
