<template>
  <div>
    <v-container class="feature-header" fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs7>
          <div class="my-title">{{ $t('special-offer') }}</div>
          <div class="caption white--text" style="line-height:normal;">{{ $t('great-discounts-on-tours-departing-within-the-next-two-months') }}</div>
        </v-flex>
        <v-flex xs5>
          <img class="img float-right" src="@/assets/images/diskon-icon.png" />
        </v-flex>
      </v-layout>
    </v-container>
    <div v-if="items && items.length">
      <v-container fluid grid-list-md pa-1>
        <v-layout row wrap>
          <div class="scrolling-wrapper">
            <v-flex v-for="(post, i) of items" :key="i" xs12 sm6 xl4>
              <home-specialoffer-card
                class="card"
                :post="post"
              ></home-specialoffer-card>
            </v-flex>
          </div>
        </v-layout>
      </v-container>
      <!-- <v-flex xs12 mt-1 text-center>
        <v-btn rounded to="/specialoffer" color="success" outlined
          >{{ $t('explore-more') }}</v-btn
        >
      </v-flex> -->
    </div>
    <loading :loading="loading"></loading>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      items: [],
      errors: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      axios
        .get(`https://balitripcenter.com/api/specialoffer/feature`)
        .then((response) => {
          this.loading = false;
          this.items = response.data.items;
          //console.log(response.data.items);
        })
        .catch((e) => {
          this.loading = false;
          this.errors.push(e);
        });
    },
  },
};
</script>
<style scoped>
.feature-header {
  padding: 16px;
  background-image: linear-gradient(to right, #07384f, #0f71a0);
  border-radius: 14px;
  margin: 4px 4px 16px 0px;
  color: white;
}

.feature-header .img {
  width: 100px;
  margin-top: 10px;
  height: 80px;
}

.my-title {
  color: orange;
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 600;
}

.scrolling-wrapper {
  display: flex;
  margin: 0 -20px;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
}

.scrolling-wrapper .card {
  display: inline-block;
  width: 260px;
  max-width: 100%;
  height: auto;
  flex: 0 0 auto;
  scroll-snap-align: center;
  margin-left: 16px;
  margin-right: -8px;
}

.yellow-bar {
  height: 4px;
  background-color: #ff6100;
  width: 10%;
}
.white-bar {
  height: 4px;
  background-color: #ddd;
  width: 40%;
  margin: 0 6px;
}

.h-inline {
  display: inline-flex;
  width: 100%;
  align-items: center;
}

img {
  width: 100%;
  height: 200px;
  align-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>