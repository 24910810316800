import Vue from 'vue';
import colors from 'vuetify/lib/util/colors'
import Vuetify, {
    VApp,
    VNavigationDrawer,
    VFooter,
    VCarousel,
    VProgressCircular,
    VList,
    VBtn,
    VIcon,
    VRating,
    VToolbar,
    VCard,
    VTabs,
    VExpansionPanel,
    VDialog,
    VDivider,
    VProgressLinear,
    VForm,
    VTextField,
    VSnackbar,
    VTextarea,
    VDatePicker,
    VSelect,
    VRadioGroup,
    VBtnToggle,
    VImg,
    VBreadcrumbs,
} from 'vuetify/lib'

import { Scroll } from 'vuetify/lib/directives'


Vue.use(Vuetify);

const opts = {
    components: {
        VApp,
        VNavigationDrawer,
        VFooter,
        VCarousel,
        VProgressCircular,
        VList,
        VBtn,
        VIcon,
        VToolbar,
        VCard,
        VRating,
        VTabs,
        VExpansionPanel,
        VDialog,
        VDivider,
        VProgressLinear,
        VForm,
        VTextField,
        VSnackbar,
        VTextarea,
        VDatePicker,
        VSelect,
        VRadioGroup,
        VBtnToggle,
        VImg,
        VBreadcrumbs,
    },
    directives: {
        Scroll,
    },
    theme: {
        themes: {
            light: {
                background: '#00a86b',
                primary: '#ff6100',
                secondary: colors.grey.darken1,
                accent: colors.amber.darken1,
                error: colors.red.accent3,
                info: colors.green.lighten4,
                success: colors.blue.darken1,
                warning: '#ff6100',
            },
        },
    }
}

export default new Vuetify(opts)